var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"card"},[_c('div',{staticClass:"w-100"},[_c('h5',{staticClass:"title-card"},[_c('i',{staticClass:"fas fa-solar-panel"}),_vm._v(" Kit atual ")])]),_c('div',{staticClass:"w-100 px-3"},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('span',[_vm._v("Descrição")]),_vm._v(" "),_c('br'),_c('h4',[_vm._v(_vm._s(_vm.product.descricao))])])],1)],1)]),_c('b-row',{staticClass:"card mt-5"},[_c('div',{staticClass:"w-100"},[_c('h5',{staticClass:"title-card"},[_c('i',{staticClass:"fas fa-pen"}),_vm._v(" Alterar kit ")])]),_c('div',{staticClass:"w-100 p-3"},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('span',[_vm._v("Descrição")]),_vm._v(" "),_c('br'),_c('h4',[_vm._v(_vm._s(_vm.description))])])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"lg":"2"}},[_c('b-form-group',{attrs:{"label":"Valor"}},[_c('b-form-input',{attrs:{"state":_vm.$v.payload.value.$dirty ?
            !_vm.$v.payload.value.$error : null},model:{value:(_vm.$v.payload.value.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.value, "$model", $$v)},expression:"$v.payload.value.$model"}})],1)],1),_c('b-col',{attrs:{"lg":"2"}},[_c('b-form-group',{attrs:{"label":"Geração (Kwh)"}},[_c('b-form-input',{attrs:{"state":_vm.$v.payload.power_estimate.$dirty ?
            !_vm.$v.payload.power_estimate.$error : null},model:{value:(_vm.$v.payload.power_estimate.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.power_estimate, "$model", $$v)},expression:"$v.payload.power_estimate.$model"}})],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Estrutura"}},[_c('b-form-select',{attrs:{"options":_vm.structures,"state":_vm.$v.payload.structure.$dirty ?
            !_vm.$v.payload.structure.$error : null},model:{value:(_vm.$v.payload.structure.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.structure, "$model", $$v)},expression:"$v.payload.structure.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Distribuidor"}},[_c('b-form-select',{attrs:{"options":_vm.suppliers,"state":_vm.$v.payload.supplier.$dirty ?
            !_vm.$v.payload.supplier.$error : null},model:{value:(_vm.$v.payload.supplier.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.supplier, "$model", $$v)},expression:"$v.payload.supplier.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('div',{staticClass:"mt-4"},[_c('i',{staticClass:"fas fa-charging-station"}),_vm._v(" "),_c('strong',[_vm._v("Inversor")]),_c('hr',{staticClass:"mt-1"})]),_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Marca"}},[_c('b-form-select',{attrs:{"options":_vm.inverters,"state":_vm.$v.payload.inverter.$dirty ?
              !_vm.$v.payload.inverter.$error : null},model:{value:(_vm.$v.payload.inverter.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.inverter, "$model", $$v)},expression:"$v.payload.inverter.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Kwp"}},[_c('b-form-input',{attrs:{"state":_vm.$v.payload.inverter_power.$dirty ?
              !_vm.$v.payload.inverter_power.$error : null},model:{value:(_vm.$v.payload.inverter_power.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.inverter_power, "$model", $$v)},expression:"$v.payload.inverter_power.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Quantidade"}},[_c('b-form-input',{attrs:{"state":_vm.$v.payload.inverter_quantity.$dirty ?
              !_vm.$v.payload.inverter_quantity.$error : null},model:{value:(_vm.$v.payload.inverter_quantity.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.inverter_quantity, "$model", $$v)},expression:"$v.payload.inverter_quantity.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('div',{staticClass:"mt-4"},[_c('i',{staticClass:"fas fa-solar-panel"}),_vm._v(" "),_c('strong',[_vm._v("Painel")]),_c('hr',{staticClass:"mt-1"})]),_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Marca"}},[_c('b-form-select',{attrs:{"options":_vm.panels,"state":_vm.$v.payload.panel.$dirty ?
                !_vm.$v.payload.panel.$error : null},model:{value:(_vm.$v.payload.panel.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.panel, "$model", $$v)},expression:"$v.payload.panel.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Kw"}},[_c('b-form-input',{attrs:{"state":_vm.$v.payload.panel_power.$dirty ?
                !_vm.$v.payload.panel_power.$error : null},model:{value:(_vm.$v.payload.panel_power.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.panel_power, "$model", $$v)},expression:"$v.payload.panel_power.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Quantidade"}},[_c('b-form-input',{attrs:{"state":_vm.$v.payload.panel_quantity.$dirty ?
                !_vm.$v.payload.panel_quantity.$error : null},model:{value:(_vm.$v.payload.panel_quantity.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.panel_quantity, "$model", $$v)},expression:"$v.payload.panel_quantity.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('div',{staticClass:"mt-4"},[_c('i',{staticClass:"fas fa-microchip"}),_vm._v(" "),_c('strong',[_vm._v("Transformador")]),_c('hr',{staticClass:"mt-1"})]),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Kva"}},[_c('b-form-input',{model:{value:(_vm.payload.transformer_kva),callback:function ($$v) {_vm.$set(_vm.payload, "transformer_kva", $$v)},expression:"payload.transformer_kva"}})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Quantidade"}},[_c('b-form-input',{model:{value:(_vm.payload.transformer_quantity),callback:function ($$v) {_vm.$set(_vm.payload, "transformer_quantity", $$v)},expression:"payload.transformer_quantity"}})],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('div',{staticClass:"mt-4"},[_c('i',{staticClass:"fas fa-plug"}),_vm._v(" "),_c('strong',[_vm._v("String Box")]),_c('hr',{staticClass:"mt-1"})]),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Entradas"}},[_c('b-form-input',{attrs:{"state":_vm.$v.payload.string_box_input.$dirty ?
                !_vm.$v.payload.string_box_input.$error : null},model:{value:(_vm.$v.payload.string_box_input.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.string_box_input, "$model", $$v)},expression:"$v.payload.string_box_input.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Saídas"}},[_c('b-form-input',{attrs:{"state":_vm.$v.payload.string_box_output.$dirty ?
                !_vm.$v.payload.string_box_output.$error : null},model:{value:(_vm.$v.payload.string_box_output.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.string_box_output, "$model", $$v)},expression:"$v.payload.string_box_output.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1)],1)],1)],1),_c('b-row',{attrs:{"align-h":"end"}},[_c('div',{staticClass:"p-3"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.confirm}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Salvar kit customizado ")])],1)])],1)]),_c('b-modal',{ref:"add-item",attrs:{"cancel-title":"Fechar","id":"add-item","ok-title":"Confirmar","title":"Adicionar produto"},on:{"hide":_vm.reset},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"disabled":_vm.spinner,"variant":"primary","size":"sm"},on:{"click":_vm.saveProduct}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.spinner),expression:"spinner"}],attrs:{"small":""}}),_c('i',{staticClass:"fas fa-save"}),_vm._v(" Salvar produto ")],1)],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo"}},[_c('b-form-select',{attrs:{"state":_vm.$v.payloadProduct.type.$dirty ?
        !_vm.$v.payloadProduct.type.$error : null,"options":_vm.typeProducts},model:{value:(_vm.$v.payloadProduct.type.$model),callback:function ($$v) {_vm.$set(_vm.$v.payloadProduct.type, "$model", $$v)},expression:"$v.payloadProduct.type.$model"}})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Descrição"}},[_c('b-form-input',{attrs:{"state":_vm.$v.payloadProduct.description.$dirty ?
        !_vm.$v.payloadProduct.description.$error : null},model:{value:(_vm.$v.payloadProduct.description.$model),callback:function ($$v) {_vm.$set(_vm.$v.payloadProduct.description, "$model", $$v)},expression:"$v.payloadProduct.description.$model"}})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Potência"}},[_c('b-form-input',{attrs:{"state":_vm.$v.payloadProduct.power.$dirty ?
        !_vm.$v.payloadProduct.power.$error : null},model:{value:(_vm.$v.payloadProduct.power.$model),callback:function ($$v) {_vm.$set(_vm.$v.payloadProduct.power, "$model", $$v)},expression:"$v.payloadProduct.power.$model"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }