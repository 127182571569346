/* eslint-disable class-methods-use-this */
import Service from './Service';

class ProductCustonService extends Service {
  constructor() {
    super('products-custon');
  }
}

const productCustonService = new ProductCustonService();
export default productCustonService;
