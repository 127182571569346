<template>
  <div>
    <b-row class="card">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-solar-panel"></i> Kit atual
        </h5>
      </div>
      <div class="w-100 px-3">
        <b-row>
          <b-col lg="12">
            <span>Descrição</span> <br>
            <h4>{{ product.descricao }}</h4>
          </b-col>
        </b-row>
      </div>
    </b-row>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-pen"></i> Alterar kit
        </h5>
      </div>

      <!-- <div class="w-100 p-3">
        <b-button v-b-modal.add-item variant="primary"> <i class="fas fa-plus"></i> Adicionar produto</b-button>
      </div> -->
      <div class="w-100 p-3">
        <b-row>
          <b-col lg="12">
            <span>Descrição</span> <br>
            <h4>{{ description }}</h4>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="2">
            <b-form-group label="Valor">
              <b-form-input
              v-model="$v.payload.value.$model"
              :state="$v.payload.value.$dirty ?
              !$v.payload.value.$error : null"
              />
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-form-group label="Geração (Kwh)">
              <b-form-input
              v-model="$v.payload.power_estimate.$model"
              :state="$v.payload.power_estimate.$dirty ?
              !$v.payload.power_estimate.$error : null"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Estrutura">
              <b-form-select
              :options="structures"
              v-model="$v.payload.structure.$model"
              :state="$v.payload.structure.$dirty ?
              !$v.payload.structure.$error : null"
              />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Distribuidor">
              <b-form-select
              :options="suppliers"
              v-model="$v.payload.supplier.$model"
              :state="$v.payload.supplier.$dirty ?
              !$v.payload.supplier.$error : null"
              />
              <b-form-invalid-feedback>
                Preenchimento obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
         <b-col lg="6">
          <div class="mt-4">
            <i class="fas fa-charging-station"></i> <strong>Inversor</strong>
            <hr class="mt-1">
          </div>
          <b-row>
            <b-col lg="12">
              <b-form-group label="Marca">
                <b-form-select
                :options="inverters"
                v-model="$v.payload.inverter.$model"
                :state="$v.payload.inverter.$dirty ?
                !$v.payload.inverter.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label="Kwp">
                <b-form-input
                v-model="$v.payload.inverter_power.$model"
                :state="$v.payload.inverter_power.$dirty ?
                !$v.payload.inverter_power.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label="Quantidade">
                <b-form-input
                v-model="$v.payload.inverter_quantity.$model"
                :state="$v.payload.inverter_quantity.$dirty ?
                !$v.payload.inverter_quantity.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          </b-col>
          <b-col lg="6">
            <div class="mt-4">
              <i class="fas fa-solar-panel"></i> <strong>Painel</strong>
              <hr class="mt-1">
            </div>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Marca">
                  <b-form-select
                  :options="panels"
                  v-model="$v.payload.panel.$model"
                  :state="$v.payload.panel.$dirty ?
                  !$v.payload.panel.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Kw">
                  <b-form-input
                  v-model="$v.payload.panel_power.$model"
                  :state="$v.payload.panel_power.$dirty ?
                  !$v.payload.panel_power.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Quantidade">
                  <b-form-input
                  v-model="$v.payload.panel_quantity.$model"
                  :state="$v.payload.panel_quantity.$dirty ?
                  !$v.payload.panel_quantity.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <div class="mt-4">
              <i class="fas fa-microchip"></i> <strong>Transformador</strong>
              <hr class="mt-1">
            </div>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Kva">
                  <b-form-input
                  v-model="payload.transformer_kva"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Quantidade">
                  <b-form-input v-model="payload.transformer_quantity"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <div class="mt-4">
              <i class="fas fa-plug"></i> <strong>String Box</strong>
              <hr class="mt-1">
            </div>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Entradas">
                  <b-form-input
                  v-model="$v.payload.string_box_input.$model"
                  :state="$v.payload.string_box_input.$dirty ?
                  !$v.payload.string_box_input.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Saídas">
                  <b-form-input
                  v-model="$v.payload.string_box_output.$model"
                  :state="$v.payload.string_box_output.$dirty ?
                  !$v.payload.string_box_output.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <div class="p-3">
            <b-button variant="primary" @click="confirm">
              <i class="fas fa-save"></i> Salvar kit customizado
            </b-button>
          </div>
        </b-row>
      </div>
    </b-row>
     <b-modal
      ref="add-item"
      cancel-title="Fechar"
      id="add-item"
      @hide="reset"
      ok-title="Confirmar"
      title="Adicionar produto"
    >
    <b-row>
      <b-col lg="12">
        <b-form-group label="Tipo">
          <b-form-select
          v-model="$v.payloadProduct.type.$model"
          :state="$v.payloadProduct.type.$dirty ?
          !$v.payloadProduct.type.$error : null"
          :options="typeProducts"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group label="Descrição">
          <b-form-input
          v-model="$v.payloadProduct.description.$model"
          :state="$v.payloadProduct.description.$dirty ?
          !$v.payloadProduct.description.$error : null"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group label="Potência">
          <b-form-input
          v-model="$v.payloadProduct.power.$model"
          :state="$v.payloadProduct.power.$dirty ?
          !$v.payloadProduct.power.$error : null"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          :disabled="spinner"
          variant="primary"
          size="sm"
          class="float-right"
          @click="saveProduct"
        >
        <b-spinner small v-show="spinner"></b-spinner>
         <i class="fas fa-save"></i> Salvar produto
        </b-button>
      </div>
    </template>
    </b-modal>
  </div>
</template>

<script>
import { required, numeric, decimal } from 'vuelidate/lib/validators';
import ProjectService from '@/Services/ProjectService';
import ProductCustonService from '@/Services/ProductCustonService';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';

export default {
  props: ['project'],
  data() {
    return {
      product: {},
      payloadProduct: {
        type: '',
        description: '',
        power: '',
      },
      spinner: false,
      payload: {
        id: false,
        value: '',
        project_id: this.project,
        description: '',
        power_estimate: '',
        inverter: '',
        inverter_power: '',
        inverter_quantity: '',
        supplier: '',
        panel: '',
        panel_power: '',
        panel_quantity: '',
        structure: '',
        transformer: '',
        transformer_kva: '',
        transformer_quantity: '',
        string_box_input: '',
        string_box_output: '',
      },
      typeProducts: [
        {
          text: 'Selecione',
          value: '',
        },
        {
          text: 'Painel',
          value: 'panel',
        },
        {
          text: 'Inversor',
          value: 'inverter',
        },
      ],
      panels: [
        {
          value: '',
          text: 'Selecione',
        },
        {
          value: 'canadian 455w mono HiKu',
          text: 'Canadian 455w',
        },
        {
          value: 'canadian 450w mono HiKu',
          text: 'Canadian 450w',
        },
        {
          value: 'dah 545w Mono Half-Cell',
          text: 'Dah 545w',
        },
        {
          value: 'jinko 450w',
          text: 'Jinko 450w',
        },
        {
          value: 'jinko 530w tiger pro mono',
          text: 'Jinko 530w',
        },
        {
          value: 'jinko 550w tiger pro mono',
          text: 'Jinko 550w',
        },
        {
          value: 'phono 535w HALF CELL MONO',
          text: 'Phono 535w',
        },
        {
          value: 'sinosolo 450w MONO',
          text: 'Sinosolo 450w',
        },
        {
          value: 'znshine 405w mono',
          text: 'Znshine 405w',
        },
      ],
      suppliers: [
        {
          value: '',
          text: 'Selecione',
        },
        {
          value: 'aldo',
          text: 'Aldo',
        },
        {
          value: 'cordeiro',
          text: 'Cordeiro',
        },
        {
          value: 'skysolar',
          text: 'Skysolar',
        },
      ],
      inverters: [
        {
          value: '',
          text: 'Selecione',
        },
        {
          value: 'growatt',
          text: 'Growatt',
        },
        {
          value: 'refusol',
          text: 'Refusol',
        },
        {
          value: 'sma',
          text: 'SMA',
        },
      ],
      structures: [
        {
          text: 'Selecione',
          value: '',
        },
        {
          text: 'Sem estrutura',
          value: 'sem estrutura',
        },
        {
          text: 'Colonial',
          value: 'colonial',
        },
        {
          text: 'Paraf Madeira',
          value: 'madeira',
        },
        {
          text: 'Paraf Metal',
          value: 'metal',
        },
        {
          text: 'Trapezoidal',
          value: 'trapezoidal',
        },
        {
          text: 'Solo',
          value: 'solo',
        },
      ],
    };
  },
  computed: {
    description: {
      get() {
        // eslint-disable-next-line max-len
        const result = `GERADOR DE ENERGIA SOLAR ${this.payload.inverter} ${this.payload.inverter_power}Kwp ${this.payload.structure} ${this.payload.panel}`;
        return result.toUpperCase();
      },
    },
  },
  validations: {
    payloadProduct: {
      description: { required },
      type: { required },
      power: { required },
    },
    payload: {
      value: { required, decimal },
      description: { required },
      inverter: { required },
      inverter_power: { required, decimal },
      inverter_quantity: { required, numeric },
      supplier: { required },
      panel: { required },
      power_estimate: { required, numeric },
      panel_power: { required, numeric },
      panel_quantity: { required, numeric },
      structure: { required },
      string_box_input: { required, numeric },
      string_box_output: { required, numeric },
    },
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  methods: {
    async getProject() {
      try {
        const { data } = await ProjectService.get(this.project);
        this.product = data.product;
        if (data.project_custon !== null) this.payload = data.project_custon;
      } catch (error) {
        console.log(error);
      }
    },
    confirm() {
      this.$toast.question(
        'Deseja realmente alterar o projeto ?',
        'Atenção',
        this.getQuestionObj(this.save, '', 'Projeto alterado'),
      );
    },
    reset() {
      this.$v.payloadProduct.$reset();
      this.payloadProduct = {
        type: '',
        description: '',
        power: '',
      };
    },
    async saveProduct() {
      this.$v.payloadProduct.$touch();
      if (!this.$v.payload.$invalid) {
        console.log('valido');
      }
    },
    async save() {
      this.payload.description = this.description;
      if (this.payload.transformer_kva > 0) this.payload.transformer = 'Transformador';

      this.$v.payload.$touch();

      if (!this.$v.payload.$invalid) {
        try {
          if (!this.payload.id) {
            await ProductCustonService.save(this.payload);
          } else {
            await ProductCustonService.edit(this.payload);
          }

          this.$router.push({ name: 'project', params: { id: this.project } });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  created() {
    this.getProject();
  },
};
</script>
